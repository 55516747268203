html {
  font-family: Roboto, Arial, serif;
}

.loading-background {
  z-index: 1500 !important;
}

.MuiTab-root {
  min-width: 105px !important;
  font-size: 11px !important;
}
